$body-bg: #000;

$brand-color: #ccf;

$primary: #ccf;

$post-color-editable:  #ccc;
$post-color-focus:     #eef;
$post-bg:  #222;
$post-bg-focus: #002;
$post-bg1: #225;
$post-border1: solid 1px #447;
$post-border2: solid 2px #669;

$bg-light-50: #406abf;
$bg-light-80: #9bf;
$bg-light-80-light: #bac6de;
$bg-light-90: #cdf;
$bg-light-95: #e5eeff;

$btn-focus-width:  0;
$input-bg: #336;
$input-color: #ccf;

$border-radius:    0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;
$nav-link-padding-y:                .9rem;

$navbar-light-color:                rgba($brand-color, .8);
$navbar-light-hover-color:          rgba($brand-color, 1);
$navbar-light-active-color:         rgba($brand-color, 1);
$navbar-light-disabled-color:       rgba($brand-color, .4);

$form-group-margin-bottom:          0;

$font-size-sm:                .75rem;

$input-btn-padding-y-sm:      .05rem;
$input-btn-padding-x-sm:      .125rem;
