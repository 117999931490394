.menu-button {
  color: #777;
  &:hover {
    color: #aaa;
  }
  &.active {
    color: #999;
    &:hover {
      cursor: default;
    }
  }
}
