@import "./design/variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";

html, body, #root {
  height: 100%;
}
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.btn-outline-primary:hover {
  background-color: #e5eeff;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

h4 {
  color: $gray-600;
}
.form-group {
  margin-bottom: 8px;
}
.col-form-label {
  font-size: 85%;
  color: $gray-500;
  padding-top: 4px;
  padding-bottom: 0;
  .language {
    color: #999;
  }
}

.nav-tabs {
  border-bottom-color: $gray-500;
  .nav-link {
    padding: 4px 12px;
  }
}
.nav-tabs .nav-link {
  border-top-color: $gray-200;
  border-left-color: $gray-200;
  border-right-color: $gray-200;
  border-bottom-color: $gray-500;
  &:hover {
    border-top-color: $gray-400;
    border-left-color: $gray-400;
    border-right-color: $gray-400;
  }
  &.active {
    border-top-color: $gray-500;
    border-left-color: $gray-500;
    border-right-color: $gray-500;
  }
  margin-right: 8px;
}
.tab-pane {
  padding: 8px;
  border-left: solid 1px $gray-500;
  border-right: solid 1px $gray-500;
  border-bottom: solid 1px $gray-500;
  min-height: 40vh;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.gmcd-select.Select--multi .Select-value {
  background-color: $gray-200;
  color: $gray-900;
  border-color: $gray-400;
  .Select-value-icon {
    background-color: theme-color-level("danger", -10);
    border-color: $gray-400;
  }
}

$saturn: .75;
$bright: 1.25;

$themes: (
  "darkGray": (
    bg0: #111,
    bg1: #222,
    bg1a:#222,
    bg2: #333,
    bg3: #666,
    bg4: #888,
    bg6: #ccc,
    color0: #fff,
    color1: #ccc,
    color2: #999,
    link1: #ccd,
    link2: #aab,
  ),
  "lightGray": (
    bg0: #fff,
    bg1: #eee,
    bg1a: #ddd,
    bg2: #bbb,
    bg3: #aaa,
    bg4: #999,
    bg6: #777,
    color0: #000,
    color1: #222,
    color2: #555,
    link1: #334,
    link2: #556,
  ),
);

@function darkTheme($name,$hue) {
  $theme: (
    bg0: hsl($hue, $saturn * 40, $bright *  5),
    bg1: hsl($hue, $saturn * 30, $bright *  8),
    bg1a:hsl($hue, $saturn * 25, $bright * 13),
    bg2: hsl($hue, $saturn * 21, $bright * 23),
    bg3: hsl($hue, $saturn * 17, $bright * 30),
    bg4: hsl($hue, $saturn * 14, $bright * 37),
    bg6: hsl($hue, $saturn * 25, $bright * 60),
    color0: hsl($hue,  50, 95),
    color1: hsl($hue,  50, 90),
    color2: hsl($hue,  33, 70),
    link1:  hsl($hue,  40, 80),
    link2:  hsl($hue,  45, 70),
  );
  @return ($name: $theme);
};

@function lightTheme($name,$hue) {
  $theme: (
    bg0: hsl($hue,100, 99),
    bg1: hsl($hue,100, 97),
    bg1a:hsl($hue, 80, 93),
    bg2: hsl($hue, 60, 85),
    bg3: hsl($hue, 43, 77),
    bg4: hsl($hue, 27, 57),
    bg6: hsl($hue, 50, 60),
    color0: hsl($hue, 33, 10),
    color1: hsl($hue, 50, 13),
    color2: hsl($hue, 33, 30),
    link1:  hsl($hue, 80, 30),
    link2:  hsl($hue, 90, 40),
  );
  @return ($name: $theme);
};

$themes: map-merge($themes,darkTheme("darkBlue"  ,240));
$themes: map-merge($themes,darkTheme("darkBluePink" ,270));
$themes: map-merge($themes,darkTheme("darkPink"  ,300));
$themes: map-merge($themes,darkTheme("darkRedPink" , 330));
$themes: map-merge($themes,darkTheme("darkRed"   ,  0));
$themes: map-merge($themes,darkTheme("darkOrange", 30));
$themes: map-merge($themes,darkTheme("darkYellow", 60));
$themes: map-merge($themes,darkTheme("darkYellowGreen", 90));
$themes: map-merge($themes,darkTheme("darkGreen", 120));
$themes: map-merge($themes,darkTheme("darkCyanGreen" , 150));
$themes: map-merge($themes,darkTheme("darkCyan" , 180));
$themes: map-merge($themes,darkTheme("darkBlueCyan", 210));

$themes: map-merge($themes,lightTheme("lightBlue"  ,240));
$themes: map-merge($themes,lightTheme("lightBluePink" ,270));
$themes: map-merge($themes,lightTheme("lightPink"  ,300));
$themes: map-merge($themes,lightTheme("lightRedPink" ,330));
$themes: map-merge($themes,lightTheme("lightRed"   ,  0));
$themes: map-merge($themes,lightTheme("lightOrange", 30));
$themes: map-merge($themes,lightTheme("lightYellow", 60));
$themes: map-merge($themes,lightTheme("lightYellowGreen", 90));
$themes: map-merge($themes,lightTheme("lightGreen", 120));
$themes: map-merge($themes,lightTheme("lightCyanGreen" , 150));
$themes: map-merge($themes,lightTheme("lightCyan" , 180));
$themes: map-merge($themes,lightTheme("lightBlueCyan" , 210));

@each $theme, $styles in $themes {

  $border1 : solid 1px map-get($styles,"bg4");
  $border2 : solid 2px map-get($styles,"bg6");

  $bg0 : map-get($styles,"bg0");
  $bg1 : map-get($styles,"bg1");
  $bg1a: map-get($styles,"bg1a");
  $bg2 : map-get($styles,"bg2");
  $bg3 : map-get($styles,"bg3");
  $bg4 : map-get($styles,"bg4");
  $bg6 : map-get($styles,"bg6");

  $color0 : map-get($styles,"color0");
  $color1 : map-get($styles,"color1");
  $color2 : map-get($styles,"color2");

  .theme-selector.#{$theme} {
    font-size: 125%;
    color: $color1;
    background-color: $bg3;
    padding: .25rem .5rem;
    &.selected {
      margin: .125rem;
      border: solid .125rem #f77;
    }
    &.inactive {
      opacity: .7;
      margin: .25rem;
    }
  }
  .episode.card.#{$theme} , .search-result.#{$theme} {
    .card-body {
      max-height: 16rem;
      overflow: clip;
    }
  }
  .episode.card.#{$theme} , .book.card.#{$theme} , .search-result.#{$theme} {
    background-color: $bg2;
    border: solid 2px $bg3;
    cursor: pointer;
    &:hover {
      border-color: #99f;
    }
    &.selected {
      background-color: $bg3;
      border: solid 2px $bg6;
      &:hover {
        border-color: #aaf;
      }
    }
    .card-title {
      opacity: 0.5;
      font-size: 85%;
    }
    .card-footer:hover {
      background-color: $bg3;
      color: #99f;
    }
    .card-content {
      font-family: Cambria, 'Times New Roman', serif;
    }
  }
  a.amazon-link {
    &:hover {
      text-decoration: none;
    }
    .book.card.#{$theme} {
      color: $color1;
      &:hover {
        background-color: $bg3;
      }
    }
  }
  .mb-main.#{$theme} {
    ::placeholder {
      color: $bg1;
      opacity: 1;
    }
    background-color: $bg0;
    a {
      color: map-get($styles,"link1");
    }
    .nav-button {
      color: $color1;
      background-color: $bg2;
      &:hover {
        background-color: $bg3;
      }
    }
    .navbar {
      .navbar-brand {
        background-color: $bg0;
        padding: .5rem .75rem;
        .app-name {
          font-size: 1.4rem;
          padding-left: .5rem;
          font-weight: bold;
        }
      }
      background-color: $bg1;
      border-color: $bg6;
      border-bottom: $border2;
      .navbar-nav li a.active {
        color: $color1;
        background-color: $bg2;
      }
      .navbar-nav .nav-item .sdc-action-button.nav-link {
        color: map-get($styles,"link1");
        background-color: $bg2;
        padding: 15px .5rem;
      }
      .app-version {
        color: #666;
        &:hover {
          color: #ccc;
          font-size: 150%;
          z-index: 1000;
          background-color: #000;
          border: solid 1px #666;
        }
      }
      .login-button {
        border-color: $bg6;
      }
      .blog-menu {
        border-right: solid 1px $bg6;
      }
      .post-suche {
        margin-left: 1rem;
        &.input-group {
          width: 25%;
        }
        .form-control {
          background-color: $bg2;
          border: $border1;
        }
        .sdc-action-button {
          padding: 0 .3rem;
          font-size: 20px;
          border: $border1;
          color: $gray-600;
          &:hover {
            background-color: $bg2;
          }
          &.visible {
            color: #66a;
          }
          &.hidden {
            color: #6a6;
          }
        }
      }
    }
    .side-panel {
      background-color: $bg1;
      color: $color0;
      h4 {
        color: $color1;
        border-color: $bg6;
        &.selectable {
          cursor: pointer;
          &:hover {
            background-color: $bg2;
          }
        }
      }
      .search-resultXXX {
        background-color: map-get($styles,"bg1a");
        border: $border1;
        &.selected {
          background-color: map-get($styles,"bg3");
          border: solid 2px map-get($styles,"bg6");
        }
      }
    }
    .content-editable , textarea.form-control , input.form-control {
      color: $color1;
      background-color: $bg3;
      border: $border1;
      &:focus {
        color: $color0;
        background-color: $bg4;
        border-color: $bg6;
      }
    }
    .episode.card {
      // border-radius: 4px;
    }
    h3.post , h3.episode , h5.episode , .post-links , .post-details , .episode-details {
      background-color: $bg2;
      border: $border1;
      .col-form-label {
        color: $color2;
      }
    }
    h5.episode {
      margin-top: 1rem;
      padding: 1rem;
      &.protected {
        text-align: center;
        color: #fc9;
      }
    }
    h3.post , h3.episode {
      color: $color1;
      .toggle {
        color: $gray-600;
        margin-left: 1rem;
        cursor: pointer;
        &:hover {
          color: $gray-300;
        }
      }
      .sdc-action-button {
        &.visible {
          color: #6a6;
        }
        &.hidden {
          color: #a66;
        }
      }
    }
    h5 .selectable {
      cursor: pointer;
      padding: 6px 12px;
      &:hover {
        background-color: $bg2;
      }
    }

    .post-details , .episode-details {
      .md-link {
        color: map-get($styles,"link1");
        &:hover {
          color: map-get($styles,"link2");
        }
      }
    }

    .post-parents > .row {
      & > .post-parent:not(:first-child) {
        border-left: $border2;
      }
      & > .post-parent {
        padding: 0;
      }
    }
    .post-links > .row {
      & > .col:not(:first-child) {
        border-left: $border2;
      }
      & > .col {
        padding: 0;
      }
    }

    .post-links .text-center.no-padding {
      &:not(:first-child) {
        border-left: $border1;
      }
      padding: 0;
      .post-primary {
        border-top: none;
      }
      :last-child {
        border-bottom: none;
      }
    }
    .post-links .row , .post-links {
      min-height: 2rem;
    }
    .col {
      border-color: $bg4;
    }
    .react-grid-layout {
      background-color: map-get($styles,"bg1a");
    }
    .react-grid-item {
      border: $border1;
      background-color: $bg2;
      overflow-y: hidden;
      .post-primary.hidden {
        opacity: .5;
      }
      .post-primary .post-link {
        font-size: 16px;
      }
      .post-link {
        font-size: 14px;
      }
      .post-text {
        font-size: .75rem;
        text-align: left;
        padding: 4px;
        color: $color2;
        h1 , h2 {
          font-size: 1.25rem;
        }
        h3 , h4 {
          font-size: 1rem;
        }
        h5 , h6 {
          font-size: .875rem;
        }
        &.hidden {
          opacity: .5;
        }
        p , ul {
          margin-bottom: .25rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
        ul {
          padding-inline-start: 1.5rem;
        }
        cite {
          width: 100%;
          text-align: right;
          display: inline-block;
        }
      }
    }
    .post-primary {
      color: $color1;
      border-top:    $border1;
      border-bottom: $border1;
      &.rgl {
        //height: 100%;
        //border: $border1 !important;
      }
    }
    .post-secondary {
      color: $color2;
      border-bottom: $border1;
      &.post-self {
        color: $color1;
        background-color: $bg4;
      }
    }
    .custom-scrollbar {
      scrollbar-width: thin;
      scrollbar-color: $bg1 $bg3;
    }
    .sec-content-panel-wrapper {
      margin-top: 1rem;
      height: calc(100vh - 80px);
      overflow-y: auto;
    }
    .sec-content-panel {
      height: calc(100vh - 80px);
      margin: 0;
      padding: .75rem;
      background-color: map-get($styles,"bg1a");
      color: $color0;
      h4 {
        margin-top: 12px;
        border-bottom: $border1;
      }
    }
    .ReactTable.-highlight .rt-tbody .rt-tr {
      &:hover {
        background-color: $bg2;
      }
      &.selected {
        background-color: $bg3;
        &:hover {
          background-color: $bg4;
        }
      }
    }
  }
}

.entry-filter {
  padding: 4px;
  border: solid 1px $gray-400;
  margin-bottom: 2px;
  .remove-filter {
    color: theme-color-level("danger", -8);
    &:hover {
      color: theme-color-level("danger", -1);
    }
  }
  &.disabled {
    font-size: 85%;
    border-color: transparent;
  }
}
.md-self {
  color: #090;
  font-weight: bold;
}
.md-label {
  &:not(:first-child) {
    &::before {
      content: " • ";
    }
  }
  &.bordered {
    font-weight: bold;
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
}
.md-link {
  font-weight: bold;
  cursor: pointer;
  white-space: pre;
  &:hover {
    text-decoration: underline;
  }
  &.bordered {
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
  .fa {
    margin-right: 4px;
  }
}
.markdown-header {
  position: relative;
  .toggle {
    color: $gray-400;
    cursor: pointer;
    border: solid 1px $gray-200;
    &:hover {
      color: $gray-700;
      border: solid 1px $gray-400;
    }
    .fa {
      margin: 0 4px;
    }
  }
}

.type-panel {
  &.expandable {
    h4 , .h4 {
      cursor: pointer;
    }
  }
  h4 , .h4 {
    &.collapsed {
      margin-bottom: 4px;
      border-bottom: solid 1px $gray-400;
    }
    &.expanded {
      margin-bottom: 0;
    }
    .fa {
      margin-right: 8px;
    }
  }
}
textarea.form-control , input.form-control {
  padding: 0 4px;
  border: solid 1px $bg-light-80-light;
  border-radius: 0;
  //color: $post-color-editable;
  //border-color: #669;
  &:focus {
    //color: $post-color-focus;
    //background-color: $post-bg-focus;
    //border-color: #99c;
  }
}
.episode-details , .post-details , .blog-details {
  label {
    text-align: right;
  }
  margin-top: .5rem;
  .content-editable {
    //border: solid 1px $bg-light-80-light;
    //color: $post-color-editable;
    margin: auto;
    //border-color: #669;
    &:focus {
      //color: $post-color-focus;
      //background-color: $post-bg-focus;
      //border-color: #99c;
    }
  }
  .viewing {
    user-select: none;
    .field-title {
      font-size: 150%;
      font-weight: bold;
    }
    .field-abstract {
      opacity: .5;
    }
  }
}
.content-editable.wb-editing {
  border-color: $bg-light-80-light;
  padding-top: 0;
  &:focus {
    color: #ccf;
    border-color: $bg-light-80-light;
    background-color: #669;
    box-shadow: 0 0 0 .2rem rgba(84, 176, 156, .25);
  }
}

.inline-help-btn {
  color: #9bf;
  cursor: help;
  &:hover {
    color: #05f;
  }
}
.panel-toolbar {
  margin-bottom: 8px;
  .btn-sm , .btn-group-sm > .btn {
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
  }
}

.side-panel {
  &.left-side {
    direction: rtl;
    * {
      direction: ltr;
    }
  }
  margin-top: 16px;
  background-color: $post-bg;
  color: #ccc;
  padding-bottom: 12px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  .side-block {
    max-height: 38vh;
    overflow-y: auto;
    margin-bottom: .5rem;
  }
  h4 {
    color: $brand-color;
    margin-top: 12px;
    border-bottom: solid 1px #66c;
  }
}

.blog-menu-entry {
  .blog-name {
    margin-right: 6rem;
  }
  .details {
    opacity: .5;
    font-size: 85%;
    margin-top: .125rem;
  }
  &.active {
    color: #000;
    .details {
      opacity: 1;
    }
  }
}

h3.post , h3.episode {
  padding: .25rem;
  text-align: center;
  .cycle-priority {
    cursor: pointer;
  }
}
h3.post , h3.episode , .post-links , .post-details , .episode-details {
  .post-primary , .post-secondary.col {
    padding: .25rem;
  }
  .markdown-panel {
    border: none;
    max-height: calc(100vh - 212px);
    padding: 8px;
    font-family: Cambria, 'Times New Roman', serif;
    p {
      padding-right: 1rem;
      text-align: justify;
      text-indent: 1em;
      &:first-child {
        text-indent: 0;
      }
    }
    pre {
      color: #999;
      margin-top: 1rem;
      margin-left: 4em;
      padding-top: .25em;
      padding-bottom: .25em;
      line-height: 1rem;
      overflow-y: clip;
    }
  }
  .sdc-action-button.action-create {
    color: #272;
    &:hover {
      color: #6c6;
    }
  }
}
.priority {
  &-0 {
    color: #666;
  }
  &-1 {
    color: #f33;
  }
  &-2 {
    color: #fa5;
  }
  &-3 {
    color: #66f;
  }
}
.pinned {
  &-false , &-null {
    color: #666;
    opacity: .5;
  }
  &-true {
    color: #3f3;
    opacity: .5;
  }
}
.post-details , .episode-details {
  font-size: 150%;
  padding: .375rem .75rem;
  .row {
    margin-bottom: .125rem;
  }
  .viewing {
    .field-name {
      font-size: 125%;
      font-weight: bold;
      margin-left: .5rem;
    }
  }
  .broken-link {
    color: #c99;
  }
}
.post-suche {
  .search-result , .pinned-result {
    margin-top: .25rem;
    padding: .25rem .5rem .25rem .25rem;
    &.hidden {
      opacity: .5;
    }
  }
  .search-result {
    .fa-2x {
      font-size: 1.4rem;
    }
  }
  .pinned-result {
    cursor: pointer;
    .fa-2x {
      font-size: 1.5rem;
    }
  }
}
.post-link {
  cursor: pointer;
  &:hover {
    color: #99f;
  }
}
.delete-button {
  color: #933;
  &:hover {
    color: #f99;
  }
}
