@import "../design/variables.scss";

.registration {
  div {
    margin-bottom: 12px;
  }
  .input-group {
    margin-bottom: 8px;
  }
  .input-group-text {
    color: $post-bg1;
    min-width: 41px;
  }
  .disclaimer {
    font-size: 85%;
    opacity: .66;
    padding-inline-start: 1rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: solid 2px $post-bg1;
  }
}
